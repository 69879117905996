import { createRouter, createWebHistory } from "vue-router";
import BlogsView from "../views/BlogsView.vue";

const routes = [
  {
    path: "/",
    name: "blogs",
    component: BlogsView,
  },
  {
    path: "/linkers",
    name: "linkers",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LinkersView.vue"),
  },
  {
    path: "/contentView/:content?",
    name: "ContentView",
    component: () => import("@/views/ContentView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
