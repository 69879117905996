import axios from "axios";
import CryptoJS from "crypto-js";
import { isEmpty } from "lodash-es";
import md5 from "md5";

import { ElNotification } from "element-plus";
import { BASE_API } from "@/config/config";

const service = axios.create({
  baseURL: BASE_API,
  timeout: 30000,
});

let AES_KEY = "qy4ph4etm35zyxrw";
let AES_IV = "v3y4mynj4iqv99my";
let ENC_PREFIX = '{"obj":"';
let ENC_SUFFIX = '"}';
let SIGN_KEY = "hccmdzqhbqps4ab3b5kp5rapcn54qrgr3fnf";

// axios 攔截器： 攔截request前狀態（access_token 狀態）
service.interceptors.request.use(
  async (config) => {
    const timestamps = new Date().getTime();
    let sign = SIGN_KEY + timestamps;
    config.data["stamp"] = timestamps;
    // console.log(
    //   "service.interceptors.request.use config.data before: " +
    //     JSON.stringify(config.data)
    // );
    let dataStr = JSON.stringify(config.data);
    if (!isEmpty(dataStr)) {
      const key = CryptoJS.enc.Utf8.parse(AES_KEY);
      const iv = CryptoJS.enc.Utf8.parse(AES_IV);
      const ciphertext = CryptoJS.AES.encrypt(dataStr, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      const DataBase64 = ciphertext.toString(); //Base64.encode(ciphertext.toString());
      config.data = ENC_PREFIX + DataBase64 + ENC_SUFFIX;
    }
    config.headers["Sign"] = md5(config.data + sign);
    config.headers["Content-Type"] = "application/json";
    // console.log("service.interceptors.request.use config.url:" + config.url);
    // console.log("service.interceptors.request.use config.data: " + config.data);
    // console.log(
    //   "service.interceptors.request.use config.headers: " + config.headers
    // );

    return config;
  },
  (err) => {
    console.log(err);
    return Promise.reject(err);
  }
);

function aesDecode(valueText) {
  const key = CryptoJS.enc.Utf8.parse(AES_KEY);
  const iv = CryptoJS.enc.Utf8.parse(AES_IV);
  const decrypt = CryptoJS.AES.decrypt(valueText, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypt.toString(CryptoJS.enc.Utf8);
}

// axios 攔截器： response前狀態
service.interceptors.response.use(
  (response) => {
    if (response.status == 200) {
      let responseData = response.data["obj"].replace(/[\n]/g, "");
      response.data = aesDecode(responseData);
      // console.log(
      //   "service.interceptors.response.use response.data:" + response.data
      // );
      return response;
    } else {
      ElNotification({
        title: "Error",
        message: response.data.msg,
        type: "error",
        duration: 2000,
      });
      return Promise.reject(response);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
