<template>
  <el-timeline>
    <el-timeline-item v-for="blog in tableData" :key="blog.id" placement="top">
      <el-card>
        <h4 @click="viewContent(blog.id)">
          {{ blog.title }} [{{ blog.categoryName }}]
        </h4>
        <p>{{ blog.createTime }} update time:{{ blog.updateTime }}</p>
      </el-card>
    </el-timeline-item>
  </el-timeline>
  <el-text class="mx-1" type="info" size="small"
    ><a href="https://beian.miit.gov.cn/" target="_blank"
      >蜀ICP备2024057542号-1</a
    ></el-text
  >
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { topMarkdown } from "@/api/blogs";
import { ElNotification } from "element-plus";
let Base64 = require("js-base64").Base64;
import { useRouter } from "vue-router";

export default {
  name: "BlogsView",
  setup() {
    const router = useRouter();
    const state = reactive({
      tableData: [],
      BlogForm: {
        id: "",
        title: "",
        categoryName: "",
        content: "",
        createTime: "",
        updateTime: "",
      },
    });

    const loading = () => {
      topMarkdown({})
        .then((res) => {
          if (res.status == 200) {
            state.tableData = JSON.parse(res.data)["data"];
            state.BlogForm.id = JSON.parse(res.data)["id"];
            state.BlogForm.title = JSON.parse(res.data)["title"];
            state.BlogForm.categoryName = JSON.parse(res.data)["categoryName"];
            state.BlogForm.createTime = JSON.parse(res.data)["createTime"];
            state.BlogForm.updateTime = JSON.parse(res.data)["updateTime"];
            state.BlogForm.content = Base64.decode(
              JSON.parse(res.data)["content"]
            );
          } else {
            ElNotification({
              title: "Error",
              type: "error",
              message: res.data.msg,
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const viewContent = (id) => {
      // console.log("BlogsView ContentView id: " + id);
      router.push({ name: "ContentView", query: { id: id } });
    };

    const handleCurrentChange = (newPage) => {
      state.currentPage = newPage;
      loading();
    };
    // mounted
    onMounted(() => {
      loading();
    });

    return {
      ...toRefs(state),
      viewContent,
      handleCurrentChange,
    };
  },
};
</script>
