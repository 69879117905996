import request from "@/utils/request";

export function topMarkdown(data) {
  return request({
    url: "blog/topMarkdown",
    method: "post",
    data,
  });
}

export function getById(data) {
  return request({
    url: "blog/getById",
    method: "post",
    data,
  });
}
