<template>
  <el-text class="mx-1" type="primary" size="large">学习笔记</el-text>
  <nav>
    <router-link to="/">Blogs</router-link> |
    <router-link to="/linkers">Linkers</router-link>
  </nav>
  <router-view />
</template>

<style lang="stylus">
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  margin auto
  color #2c3e50
  margin-top 60px
  max-width 1080px !important;
</style>
